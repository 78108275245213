/**
 * Subscribes to a specified event by adding an event listener.
 *
 * @param {string} eventName - The name of the event to subscribe to.
 * @param {EventListenerOrEventListenerObject} listener - The event listener to add.
 */
const subscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
  window.addEventListener(eventName, listener);
};

/**
 * Unsubscribes from a specified event by removing an event listener.
 *
 * @param {string} eventName - The name of the event to unsubscribe from.
 * @param {EventListenerOrEventListenerObject} listener - The event listener to remove.
 */
const unsubscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
  window.removeEventListener(eventName, listener);
};

/**
 * Publishes an event with optional data.
 *
 * @template T - The type of the event data.
 * @param {string} eventName - The name of the event to publish.
 * @param {T} [data] - The optional data to include with the event.
 */
const publish = <T>(eventName: string, data?: T) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
};

export { publish, subscribe, unsubscribe };
