import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register';

import { configLogger } from '~/utils/logging/configure';
import LoggerFactory from '~/utils/logging/LoggerFactory';

import App from './App';
import { loadCatalog } from './i18n';
import { uuidv4Math } from './utils/uuidv4Math';

import { registerSession } from '~/workers/session/session-client';
import { registerWatchdog } from '~/workers/watchdog/watchdog-client';

import type { ISettings, ITenantSettings, IUserSettings } from '~/types/application';

import './error';
import './performance';

import './index.css';

// load initial language, you can detect here a user language with your preferred method
await loadCatalog('ja');

window.settings = {} as ISettings;
window.settings.tenant = {} as ITenantSettings;
window.settings.user = {} as IUserSettings;
window.instanceId = uuidv4Math();

if (window.accessToken == null && document.location.hash.length > 0) {
  window.accessToken = document.location.hash.substring(1);
  if (window.history) window.history.replaceState(null, '', '/');
}
if (window.accessToken == null) {
  if (window.navigation) {
    void (async () => {
      void (await window.navigation?.navigate(`/login`, { history: 'replace' }).finished);
    })();
  } else {
    window.location.href = '/login';
  }
}

void registerSW();
void registerSession();
void registerWatchdog();

configLogger();

const logger = LoggerFactory.getLogger('main');
logger.info('startup');

window.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    const form = new FormData();
    form.set('instanceId', window.instanceId ?? '');
    form.set('clientId', localStorage.getItem('client-id') ?? '');
    form.set('fingerprint', localStorage.getItem('fingerprint') ?? '');
    form.set('tenantId', window.tenantId ?? '');
    form.set('tenantHandle', window.tenantHandle ?? '');
    form.set('userId', window.userId ?? '');
    form.set('userCode', window.userCode ?? '');
    form.set('userName', window.userName ?? '');
    navigator.sendBeacon('/api/unload', form);
  }
});

// const accessToken = window.sessionStorage.getItem("accessToken");
// const accessToken = document.location.search.slice(1)
// if (document.location.pathname ==='/' && accessToken != null){
//   publish<string>("login", accessToken);
// }
// window.addEventListener("unload", () => {
//   publish("logout", {});
// });

// axios.interceptors.response.use(
//   response => response,
//   // eslint-disable-next-line @typescript-eslint/require-await
//   async error => {
//     // eslint-disable-next-line @typescript-eslint/no-shadow
//     const log = LoggerFactory.getLogger('axios');
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//     switch (error.response?.status) {
//       case 500:
//         log.warn('HTTP 500');
//
//         return false;
//       case 404:
//         log.warn('HTTP 404');
//
//         return false;
//       case 401:
//         log.warn('HTTP 401');
//         publish('logout', {});
//
//         return false;
//       default:
//         return true;
//     }
//   }
// );

// if (Notification.permission === "granted") {
//   alert("we have permission");
// } else if (Notification.permission !== "denied") {
//   Notification.requestPermission().then(permission => {
//     console.log(permission);
//   });
// }
// if ('Notification' in window){
//   // 許可を求める
//   Notification.requestPermission()
//     .then((permission) => {
//       if (permission == 'granted') {
//         // 許可
//       } else if (permission == 'denied') {
//         // 拒否
//       } else if (permission == 'default') {
//         // 無視
//       }
//     });
// }

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// setTimeout(() => {
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// }, 5000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// void reportWebVitals(sendToAnalytics);
