/**
 * Encodes the provided parts into a base64 string.
 *
 * @param {...any[]} parts - The parts to be encoded.
 * @returns {Promise<string>} A promise that resolves to the base64 encoded string.
 */
export const base64Encode = (...parts: any[]) =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      const offset = result.indexOf(',') + 1;
      resolve(result.slice(offset));
    };
    reader.readAsDataURL(new Blob(parts));
  });
