/**
 * Generates a UUID (version 4) using Math.random().
 *
 * @returns {string} A randomly generated UUID (version 4).
 */
export const uuidv4Math = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};
