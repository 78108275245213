/**
 * Groups the elements of an array based on the provided key function.
 *
 * @template K - The type of the keys.
 * @template V - The type of the values.
 * @param {readonly V[]} array - The array to group.
 * @param {(cur: V, idx: number, src: readonly V[]) => K} getKey - The function to determine the key for each element.
 * @returns {[K, V[]][]} An array of key-value pairs, where each key is associated with an array of elements.
 */
export const groupBy = <K, V>(array: readonly V[], getKey: (cur: V, idx: number, src: readonly V[]) => K): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key) || [];
      list.push(cur);
      map.set(key, list);

      return map;
    }, new Map<K, V[]>())
  );

declare global {
  interface Array<T> {
    /**
     * Groups the elements of the array based on the provided key function.
     *
     * @template K - The type of the keys.
     * @param {(cur: T, idx: number, src: readonly T[]) => K} getKey - The function to determine the key for each element.
     * @returns {[K, T[]][]} An array of key-value pairs, where each key is associated with an array of elements.
     */
    groupBy<K>(getKey: (cur: T, idx: number, src: readonly T[]) => K): [K, T[]][];
  }
}

/**
 * Groups the elements of the array based on the provided key function.
 *
 * @template K - The type of the keys.
 * @param {(cur: any, idx: number, src: readonly any[]) => K} getKey - The function to determine the key for each element.
 * @returns {[K, any[]][]} An array of key-value pairs, where each key is associated with an array of elements.
 */
Array.prototype.groupBy = function <K>(getKey: (cur: any, idx: number, src: readonly any[]) => K): [K, any[]][] {
  return groupBy(this, getKey);
};
