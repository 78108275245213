'use client';
import { lazy, Suspense, useEffect } from 'react';

import { Loader } from '@mantine/core';

import ApplicationLog from '~/utils/ApplicationLog';

import { axiosInstance as axios } from '~/axios';

import { watchdog } from '~/workers/watchdog/watchdog-client';

const WorkSchedule = lazy(() => import('src/features/aspi/WorkdayPlanning'));

const WorkdayScheduleView = () => {
  useEffect(() => {
    void ApplicationLog(axios, 'workdayPlanning', 'enter', '');
    watchdog('workdayPlanning', '稼働予定管理');

    return () => {
      void ApplicationLog(axios, 'workdayPlanning', 'enter', '');
    };
  }, []);

  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1190px' }}>
        <WorkSchedule />
      </div>
    </Suspense>
  );
};

export default WorkdayScheduleView;
