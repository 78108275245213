/**
 * Decodes a JSON Web Token (JWT) and returns the payload as a specified type.
 *
 * @template T - The type of the decoded payload.
 * @param {string} token - The JWT to decode.
 * @returns {T} The decoded payload of the JWT.
 */
export const decodeJwt = <T>(token: string): T => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(decodeURIComponent(escape(window.atob(base64)))) as T;
};
