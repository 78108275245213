import React, { memo, useEffect, useState } from 'react';

import classes from '~/layouts/Default.module.css';

import { axiosInstance as axios } from '~/axios';

export const Footer = memo(() => {
  const [specVersion, setSpecVersion] = useState<string>('');
  const [implVersion, setImplVersion] = useState<string>('');

  useEffect(() => {
    void axios.get<string>('/api/version/implementation', {}).then(res => {
      window.implVersion = res.data;
      setImplVersion(res.data);
    });

    void axios.get<string>('/api/version/specification', {}).then(res => {
      window.specVersion = res.data;
      setSpecVersion(res.data);
    });
  }, []);

  return (
    <footer className={classes.footer}>
      <div className="flex flex-row justify-between pb-4 pr-4">
        <div className="font-body text-[9pt] text-gray-900">&copy; AQIT Corporation.</div>
        <div className="font-body text-[9pt] text-gray-900" title={implVersion}>
          ASPI Version {specVersion}
        </div>
      </div>
    </footer>
  );
});
